import '../styles/globals.scss'
import type { AppProps } from 'next/app'
import { RecoilRoot } from 'recoil'
import { SessionProvider } from 'next-auth/react'
import Script from 'next/script'
import Smartlook from 'smartlook-client'
import { useEffect } from 'react'
import { CacheProvider } from '@emotion/react'
import createEmotionCache from '@lib/createEmotionCache'

const clientSideEmotionCache = createEmotionCache()

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    Smartlook.init('3f49ab5efcfa7844e151ffc130886ec3bfb3c55e')
  }, [])

  return (
    <CacheProvider value={clientSideEmotionCache}>
      <SessionProvider session={pageProps.session}>
        <RecoilRoot>
          <Component {...pageProps} />
        </RecoilRoot>
        <Script src="/pureChat.js" strategy="lazyOnload" />
        <Script
          async
          src="https://cdn.promotekit.com/promotekit.js"
          data-promotekit="232d2f15-fd7c-4f8b-b1ae-1e9a56903056"
          strategy="lazyOnload"
        />
        <Script
          id="profitwell-js"
          data-pw-auth="27b0e9b3ec0cd4576fd17117bbea6ffd"
          src="/profitwell.js"
          strategy="lazyOnload"
        />
        <Script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-RCHJWYHNDQ"
          strategy="lazyOnload"
        />
        <Script id="google-tag-manager">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-N9DWCGPV');`}
        </Script>
      </SessionProvider>
    </CacheProvider>
  )
}

export default MyApp
